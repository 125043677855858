.row {
    flex-direction: row;
    display: flex;
    font-size: 15px;
    margin:5px 0;
}

.title {
    width: 20%;
    font-weight: bold;
    color: #2485cb;
    margin-right: 10px;
}

.content{
    word-break: break-word;
}

.content1{
    word-break: break-word;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mainHead {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.searchContent {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
}

.searchButton {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.staticsArea{
    display: flex;
    flex-direction: row;
}

.staticsCard{
    border-radius: 4px;
    background: #ffffff;
    width: 200px;
    height: 150px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.staticsCardTitle{
    font-weight: bold;
}

.staticsCardText{
    margin-top: 10px;
    font-size: 50px;
}